<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">修改密碼</p>
      </div>
    </div>
    <div class="nick">
      <input
        type="password"
        placeholder="原密碼"
        @keyup="getFocus"
        v-model="password"
      />
    </div>
    <div class="nick">
      <input
        type="password"
        placeholder="新密碼"
        @keyup="getFocus"
        v-model="passwordNew"
      />
    </div>
    <div class="nick">
      <input
        type="password"
        placeholder="確認新密碼"
        @keyup="getFocus"
        v-model="passwordRepeat"
      />
    </div>
    <button :class="{ submitBtn: true, blue: isBlue }" @click="submits">
      提交
    </button>
  </div>
</template>
<script>
import { changePassword } from "apiurl/user";
import { isPwd, encryptStr, isNull } from "utils/core/kernel";
// require("utils/core/g");
export default {
  data() {
    return {
      isBlue: false,
      password: "",
      passwordNew: "",
      passwordRepeat: ""
    };
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    getFocus() {
      if (
        isNull(this.password) ||
        isNull(this.passwordNew) ||
        isNull(this.passwordRepeat)
      ) {
        this.isBlue = false;
      } else {
        this.isBlue = true;
      }
    },
    submits() {
      if (
        isNull(this.password) ||
        isNull(this.passwordNew) ||
        isNull(this.passwordRepeat)
      ) {
        return false;
      } else if (
        !isPwd(this.password) ||
        !isPwd(this.passwordNew) ||
        !isPwd(this.passwordRepeat)
      ) {
        this.Toast({
          message: "六位以上字母數字符號隨意組合",
          duration: 2000,
          position: "top"
        });
      } else {
        changePassword({
          password: encryptStr(this.password),
          passwordNew: encryptStr(this.passwordNew),
          passwordRepeat: encryptStr(this.passwordRepeat)
        }).then(res => {
          if (res.success) {
            this.Toast({
              message: res.message,
              duration: 2000,
              position: "top"
            });
            this.$router.go(-1);
          }
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.nick
    margin 40px 51px 0 44px
    border-bottom 2px solid #D7D7D7
    input
        padding 19px 0px
        width 100%
        font-size 26px
        placeMain(#CFCFCF, 26px, 0px)
        &.getCode
            width 60%
    .codeimg
        width 157px
        height 49px
        background rgba(249, 249, 249, 1)
        border-radius 26px
        border 2px solid rgba(238, 238, 238, 1)
        text-align center
        line-height 49px
        font-size 22px
        display block
.submitBtn
    width 332px
    height 56px
    background rgba(216, 216, 216, 1)
    border-radius 28px
    font-size 28px
    color #fff
    display block
    margin 298px auto 0
    &.blue
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
</style>
